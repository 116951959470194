'use client';

import { FC, useId } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { INLINES } from '@contentful/rich-text-types';
import Link from 'next/link';
import { match, P } from 'ts-pattern';

import { InputFragment } from '../formikInput/input.fragment';
import { ContentfulRichText } from '../../../types/index';
import { InputWithConditionalInputsFragment } from '../inputWithConditionalInputs/inputWithConditionalInputs.fragment';
import { InputWithConditionalInputs } from '../inputWithConditionalInputs';
import { isSimpleInput } from '../inputWithConditionalInputs/isSimpleInput';

import Text from 'humanity/primitives/text';
import Heading from 'humanity/primitives/heading';
import Flex from 'humanity/primitives/flex';
import { FormikRadioCardGroup } from 'humanity/components/formikRadioCardGroup';
import Image from 'humanity/primitives/image';
import { RadioCard } from 'humanity/components/radioCardGroup';
import FormikInput from 'humanity/components/formikInput';
import SimpleRichText from 'humanity/components/simpleRichText';
import { track } from 'utils/analytics';
import Box from 'humanity/primitives/box';

const StyledRadioCardGroup = styled(FormikRadioCardGroup)(({ showLabel }) =>
  css({
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: showLabel ? 'auto' : 484,
    mx: 'auto',
  })
);

const StyledRadioCard = styled(RadioCard)(
  css({
    flexGrow: 1,
  })
);

const TextContainer = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: 3,
    mb: 5,
    mx: [0, null, 'auto'],
    maxWidth: 500,
  })
);

const RadioCardGroupWrapper = styled(Box)(
  css({
    width: '100%',
    borderBottomWidth: [1, null, 0],
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray20',
    pt: 4,
    pb: 5,
    '&:first-child': {
      pt: 0,
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  })
);

//
const optionGroupProps = {
  width: ['100%', null, 'auto'],
};

const SingleRadioCard: FC<{
  input: InputFragment;
  headingId?: string;
  showLabel?: boolean;
}> = ({ input, headingId = null, showLabel = false }) => (
  <StyledRadioCardGroup
    key={input.sys?.id}
    name={input.fieldName}
    tooltip={input.tooltip?.json}
    showLabel={showLabel}
    label={input.label}
    defaultValue={input.defaultValue}
    aria-labelledby={showLabel ? undefined : headingId}
    optionGroupProps={optionGroupProps}
    required
  >
    {input.optionsCollection?.items?.map((option) => (
      <StyledRadioCard
        key={option.value}
        value={option.value}
        label={option.label}
        small={showLabel}
        buttonProps={showLabel ? { py: 2 } : { width: ['100%', null, 145], height: 75 }}
      >
        {option.image ? (
          <Image
            src={option.image.image?.url}
            width={option.image.width}
            height={option.image.height}
            alt={option.label}
          />
        ) : (
          option.label
        )}
      </StyledRadioCard>
    ))}
  </StyledRadioCardGroup>
);

const Input: FC<{
  input: InputFragment;
  formId: string;
}> = ({ input, formId }) => {
  // if the input has options, map them to a format our select component can use
  const mappedOptions = input?.optionsCollection?.items?.length
    ? [
        // create an "empty" option to be placed at the front of the array, so that
        // nothing is selected by default
        {
          label: '',
          value: '',
          disabled: true,
          hidden: true,
        },
        // add the actual options to the end of our 1 item "empty" option array and
        // map the contentful fields to an object our Select component will understand
        ...input.optionsCollection.items.map((option) => ({
          label: option.label,
          value: option.value,
        })),
      ]
    : undefined;

  return (
    <FormikInput
      type={input.type}
      hidden={input.hidden}
      label={input.label}
      required={input.required}
      name={input.fieldName}
      options={mappedOptions}
      inputId={`${formId}-${input.fieldName}`}
      inputWidth={input.width}
      autocompleteValue={input.autocompleteValue ?? undefined}
      placeholder={input.placeholder ?? undefined}
    />
  );
};

export const SignupStepInput: FC<{
  formId: string;
  headingId: string;
  input: InputFragment;
}> = ({ formId, headingId, input }) =>
  match(input)
    .with({ type: 'radio' }, (radioInput) => (
      <RadioCardGroupWrapper key={radioInput.sys.id}>
        <SingleRadioCard input={radioInput} headingId={headingId} showLabel />
      </RadioCardGroupWrapper>
    ))
    .otherwise(() => <Input input={input} formId={formId} key={input.sys.id} />);

type Props = {
  title: string;
  subtitle?: string | null;
  bottomText?: ContentfulRichText;
  inputs: (InputFragment | InputWithConditionalInputsFragment)[];
};

export const SignupStep: FC<Props> = ({
  title,
  subtitle = '',
  bottomText,
  inputs = [],
}) => {
  const headingId = `signup-step-heading-${useId()}`;
  const formId = `signup-step-form-${useId()}`;

  return (
    <>
      <TextContainer>
        {subtitle?.length && <Text variant="body">{subtitle}</Text>}
        <Heading
          id={headingId}
          variant={['h6', null, 'h5', 'h4']}
          textAlign={['left', null, 'center']}
        >
          {title}
        </Heading>
      </TextContainer>
      {/* Since the Signup Step model just accepts an array of inputs, assume a
          single input of type 'radio' should be rendered as a card group. */}
      {inputs.length === 1 && isSimpleInput(inputs[0]) && inputs[0]?.type === 'radio' ? (
        <div>
          <SingleRadioCard input={inputs[0]} headingId={headingId} />
        </div>
      ) : (
        /* TODO: Designs show multiple container widths that don't really follow
        any specific rules. Figure out some rules. */
        <Flex
          sx={{
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            mt: -2,
            width: '100%',
            textAlign: 'left',
          }}
        >
          {inputs?.map((_) =>
            match(_)
              .with({ input: P.nonNullable }, (input) => (
                <InputWithConditionalInputs
                  conditionallyShownInputs={input.conditionallyShownInputsCollection.items.map(
                    (conditionalInput) => (
                      <SignupStepInput
                        formId={formId}
                        key={conditionalInput.sys.id}
                        headingId={headingId}
                        input={conditionalInput}
                      />
                    )
                  )}
                  fieldName={input.input.fieldName}
                  key={input.sys.id}
                  input={
                    <SignupStepInput
                      formId={formId}
                      headingId={headingId}
                      input={input.input}
                    />
                  }
                  shouldShowConditionalInputs={(value) => value === 'yes'}
                />
              ))
              .otherwise((input) => (
                <SignupStepInput
                  formId={formId}
                  headingId={headingId}
                  key={input.sys.id}
                  input={input}
                />
              ))
          )}
        </Flex>
      )}
      {bottomText?.json && (
        <TextContainer mt={5}>
          <SimpleRichText
            variant="body"
            content={bottomText?.json}
            renderOptions={{
              renderNode: {
                [INLINES.HYPERLINK]: (node, children) => (
                  <Link href={node.data?.uri} onClick={() => track('form_secondary_cta')}>
                    {children}
                  </Link>
                ),
              },
            }}
          />
        </TextContainer>
      )}
    </>
  );
};
