import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';

import { isSimpleInput } from '../humanity/components/inputWithConditionalInputs/isSimpleInput';
import { SignupStepFragment } from '../humanity/components/signupStep/signupStep.fragment';

import useSignupModal from 'hooks/useSignupModal';
import {
  CURRENT_EMPLOYEE_ROLES,
  FAST_TRACK_STORAGE_KEY,
  NON_OWNER_REDIRECT_PATH,
  NON_OWNER_ROLES,
  PROD_REDIRECT_URL,
} from 'utils/signupConstants';
import FastTrackClient from 'apiClients/fastTrack';
import { useHubspotForm } from 'hooks/useHubspotForm';
import { track } from 'utils/analytics';
import { useRecaptcha } from 'hooks/useRecaptcha';
import { logger } from 'utils/logger';
import { trackEmailCollected } from 'utils/emailCollected';
import useVwo from 'hooks/useVwo';
import useAnalytics from 'hooks/useAnalytics';

const getFastTrackCookieData = async () => {
  try {
    const { data } = await axios.get('/api/fast-track');
    return data;
  } catch (err) {
    logger.warn('Error fetching fast track cookie data', err);
    return null;
  }
};

const handleFastTrackQualified = async (vals) => {
  track('self_service_qualified', vals);
  const res = await FastTrackClient.create(vals);

  return res;
};

const setValsInStorage = (vals) => {
  try {
    sessionStorage.setItem(FAST_TRACK_STORAGE_KEY, JSON.stringify(vals));
  } catch (err) {
    logger.warn('Error setting fast track values in storage', err);
  }
};

export const useFastTrack = ({
  steps,
  formMetadata,
  employeeMaximum = null,
  fastTrackPayrolls,
}: {
  steps: SignupStepFragment[];
  formMetadata: {
    hubspotFormId: string;
    hubspotSandboxFormId: string;
    salesforceCampaignId: string;
    formType: string;
  };
  employeeMaximum?: number | null;
  fastTrackPayrolls: string[];
}) => {
  const { hubspotFormId, hubspotSandboxFormId, salesforceCampaignId, formType } =
    formMetadata;

  const vwoClient = useVwo();
  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();
  const { submitLead, bookingUrl, showModal, onModalClose } = useSignupModal();
  const { submitForm: submitHubspotForm } = useHubspotForm(
    hubspotFormId,
    hubspotSandboxFormId,
    salesforceCampaignId,
    formType
  );
  const [initialValues, setInitialValues] = useState<
    ({ stepNumber: number } & Record<string, unknown>) | undefined
  >(undefined);
  const [employerId, setEmployerId] = useState<string | undefined>(undefined);
  const [planId, setPlanId] = useState<string | undefined>(undefined);
  const [fromGusto, setFromGusto] = useState<boolean>(false);
  // generate empty initial value for each input in our steps
  // setting this prevents weird behavior with selects and avoids inputs
  // switching from uncontrolled to controlled
  const emptyInitialValues = useMemo(
    () =>
      steps
        .flatMap((step) => step.inputsCollection.items)
        .flatMap((input) =>
          isSimpleInput(input)
            ? [input]
            : [input.input, ...input.conditionallyShownInputsCollection.items]
        )
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr?.fieldName]: '',
          }),
          { stepNumber: 0 }
        ),
    [steps]
  );
  const { handleSubmitWithRecaptcha } = useRecaptcha();
  const router = useRouter();
  const { employerId: employerIdFromQuery, planId: planIdFromQuery } = router.query;

  useEffect(() => {
    const initializeValues = async () => {
      try {
        const cookieData = (await getFastTrackCookieData()) ?? {};

        let initialVals = {
          ...emptyInitialValues,
          ...cookieData,
        };

        const storageVals = sessionStorage.getItem(FAST_TRACK_STORAGE_KEY);
        if (storageVals) {
          initialVals = {
            ...initialVals,
            ...JSON.parse(storageVals),
          };
        }

        const employerIdFromCookie = cookieData?.employerId;
        const planIdFromCookie = cookieData?.planId;

        const employerIdValue = employerIdFromQuery ?? employerIdFromCookie;
        const planIdValue = planIdFromQuery ?? planIdFromCookie;

        setEmployerId(employerIdValue);
        setPlanId(planIdValue);
        setInitialValues({ ...initialVals });
        setFromGusto(Boolean(employerIdValue && planIdValue && initialVals?.email));
        if (
          cookieData &&
          employerIdValue &&
          planIdValue &&
          sessionStorage.getItem('SSO_EVENT_FIRED') !== 'true'
        ) {
          track('sso_event', {
            ...cookieData,
            partner_customer_id: cookieData?.gusto_uuid,
            partner: 'Gusto',
            // Email + nexus IDs are used for syncing to salesforce
            nexus_employer_plan_uuid: `${employerIdValue}:${planIdValue}`,
            nexus_employer_uuid: employerIdValue,
            nexus_plan_uuid: planIdValue,
            promo_code: router.query.promo_code,
            landing_page: window.location.pathname,
          });
          sessionStorage.setItem('SSO_EVENT_FIRED', 'true');
        }
      } catch (err) {
        logger.warn('Error initializing fast track values', err);
        setInitialValues({ ...emptyInitialValues });
      }
    };

    initializeValues();
  }, [employerIdFromQuery, emptyInitialValues, planIdFromQuery, router.query.promo_code]);

  type FormValues = Record<string, string> & { stepNumber: number };

  const _handleNextStep = async (vals: FormValues): Promise<boolean> => {
    setValsInStorage({ ...vals });

    if (vals.role && CURRENT_EMPLOYEE_ROLES.includes(vals.role)) {
      await submitLead(vals, formMetadata);
      return false;
    }

    // Only submit to HS if submitLead will not be called, since it will be sent there
    submitHubspotForm(vals);

    if (vals.role && NON_OWNER_ROLES.includes(vals.role)) {
      track('conversion_partner', vals);

      await router.push(NON_OWNER_REDIRECT_PATH);

      return false;
    }

    if (vals.stepNumber === 1 && vals.email) {
      trackEmailCollected(vals, vwoClient, hinAnonymousId);
    }

    const isLastStepOrRedirect = (stepNumber) =>
      stepNumber >= steps.length - 1 || steps[stepNumber]?.redirectOnSubmit;

    const meetsBasicCriteria = () =>
      vals.has_retirement_plan?.toLowerCase() === 'no' &&
      vals.is_controlled_group?.toLowerCase() === 'no' &&
      (vals.non_profit?.toLowerCase() === 'no' ||
        vals.isChurch?.toLowerCase() === 'no') &&
      vals.union_employees?.toLowerCase() === 'no';

    const isWithinEmployeeLimit = () => {
      if (employeeMaximum === null) return true;
      const employeeMaxValue =
        Number(vals.employeesRange?.match(/(\d+)(?!.*\d)/)?.[0]) || 0;
      return employeeMaxValue <= employeeMaximum;
    };

    const hasValidPayrollProvider = () =>
      fastTrackPayrolls === null || fastTrackPayrolls?.includes(vals.payrollProvider);
    if (
      isLastStepOrRedirect(vals.stepNumber) &&
      meetsBasicCriteria() &&
      isWithinEmployeeLimit() &&
      hasValidPayrollProvider()
    ) {
      // redirect user to admin onboarding if qualified
      const { redirectUrl } = await handleFastTrackQualified(vals);
      let url = new URL(PROD_REDIRECT_URL);
      try {
        url = new URL(redirectUrl || PROD_REDIRECT_URL);
      } catch (err) {
        logger.error('Error parsing redirect URL', err);
      }
      if (employerId) {
        url.searchParams.append('employerId', employerId as string);
      }
      if (planId) {
        url.searchParams.append('planId', planId as string);
      }
      window.location.href = url.toString();
      return false;
    }

    // If we make it this far, the user should continue the flow. Any other actions
    // should probably be handled before the redirect check/HS submit, since they would
    // indicate an early exit from the flow.
    return true;
  };

  const handleNextStep = async (vals): Promise<boolean> =>
    handleSubmitWithRecaptcha(_handleNextStep, vals, 'fast_track_step');

  const _handleSubmit = async (vals) => {
    await submitLead(vals, formMetadata);
  };

  const handleSubmit = async (vals) =>
    handleSubmitWithRecaptcha(_handleSubmit, vals, 'fast_track_submit');

  // Subset of steps within _handleNextStep, but does not redirect the user
  const saveStepProgress = (vals) => {
    setValsInStorage({ ...vals });

    submitHubspotForm(vals);

    if (vals.stepNumber === 1 && vals.email) {
      trackEmailCollected(vals, vwoClient, hinAnonymousId);
    }
  };

  return {
    initialValues,
    fromGusto,
    handleNextStep,
    handleSubmit,
    bookingUrl,
    saveStepProgress,
    showModal,
    onModalClose,
  };
};
